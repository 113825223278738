/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '../../../../node_modules/@mkitio/gatsby-theme-password-protect/src/utils/utils'
import './reset.css';
import Icon from '../../../svg/logo.svg';

const styles = {
  wrapper: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    width: '100%',
    height: '48px',
    borderRadius: '4px'
  },
  button: {
    width: '100%',
    height: '48px',
    color: '#fff',
    border: '1px solid #E9664A',
    borderRadius: '4px',
    marginTop: '16px',
    textTransform: 'uppercase',
    fontFamily: 'sans-serif'
  },
  buttonHover: {
    background: '#fff',
    color: '#000000',
    cursor: "pointer"
  },
  link: {
    color: '#fff',
    fontFamily: 'sans-serif',
    fontWeight: "bold"
  },
  linkHover: {
    color: 'dodgerblue'
  }
};

const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);
  const [isThemeHovered, themeHover] = useState(false);
  const [isSiteHovered, siteHover] = useState(false);

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <div style={styles.wrapper} className="pass-wrap">
      <Icon />
      <h1 style={{ color: '#fff' }}>Manual Ecomerciar</h1>
      <h4 style={{ color: '#fff' }}>Ingresar password</h4>

      <form onSubmit={onSubmit} style={{ width: '320px' }}>
        <input
          name="password"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          style={styles.input}
        />

        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isButtonHovered ? styles.buttonHover : null)
          }}
          className="pass-button"
          onMouseEnter={() => buttonHover(true)}
          onMouseLeave={() => buttonHover(false)}
        >
          Ingresar
        </button>
      </form>
    </div>
  );
};

export default PasswordProtect;
