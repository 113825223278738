// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---readme-md": () => import("./../README.md" /* webpackChunkName: "component---readme-md" */),
  "component---docs-backups-md": () => import("./../docs/Backups.md" /* webpackChunkName: "component---docs-backups-md" */),
  "component---docs-emails-md": () => import("./../docs/Emails.md" /* webpackChunkName: "component---docs-emails-md" */),
  "component---docs-git-md": () => import("./../docs/Git.md" /* webpackChunkName: "component---docs-git-md" */),
  "component---docs-integraciones-md": () => import("./../docs/Integraciones.md" /* webpackChunkName: "component---docs-integraciones-md" */),
  "component---docs-operaciones-md": () => import("./../docs/Operaciones.md" /* webpackChunkName: "component---docs-operaciones-md" */),
  "component---docs-seguridad-md": () => import("./../docs/Seguridad.md" /* webpackChunkName: "component---docs-seguridad-md" */),
  "component---docs-csv-tips-md": () => import("./../docs/csv-tips.md" /* webpackChunkName: "component---docs-csv-tips-md" */),
  "component---docs-index-mdx": () => import("./../docs/index.mdx" /* webpackChunkName: "component---docs-index-mdx" */),
  "component---docs-wordpress-md": () => import("./../docs/Wordpress.md" /* webpackChunkName: "component---docs-wordpress-md" */)
}

